export const useIntersectionObserver = () => {
  function observe(el, callback, params) {
    let paused = false;
    let intersectionObserver = new IntersectionObserver((entries, observer) => {
      if (paused) {
        return;
      }

      entries.forEach((entry) => {
        callback(entry.isIntersecting);
      });
    }, params);
    intersectionObserver.observe(el);
    return {
      intersectionObserver,
      stop() {
        intersectionObserver.unobserve(el);
        intersectionObserver.disconnect();
        intersectionObserver = null;
      },
      // pause
      pause() {
        paused = true;
      },
      // resume
      resume() {
        paused = false;
      },
    };
  }
  return {
    observe,
  };
};
