import { useRootStore } from "@/stores/root";
import { useIntersectionObserver } from "@/composables/useIntersectionObserver";
import { watch } from "vue";

export const useTransparentAppHeader = () => {
  const rootStore = useRootStore();
  const { height: windowH } = useWindowSize();
  const { observe } = useIntersectionObserver();
  let rootMargin = "0px 0px 0px 0px";
  let stopObserver;
  let unwatch;

  function initObserver($el) {
    const { stop } = observe(
      $el,
      (isIntersecting) => {
        rootStore.setAppHeaderOnColor(isIntersecting);
      },
      {
        rootMargin: rootMargin,
        root: null,
      }
    );
    return stop;
  }

  function init($el) {
    destroy();

    rootMargin = `-${Math.abs(rootStore.appHeaderBtnMirageY)}px 0px -${Math.abs(
      windowH.value - rootStore.appHeaderBtnMirageY
    )}px 0px`;

    stopObserver = initObserver($el);

    unwatch = watch(
      () => [rootStore.appHeaderBtnMirageY, windowH.value],
      (value) => {
        stopObserver();
        rootMargin = `-${Math.abs(value[0])}px 0px -${Math.abs(
          value[1] - value[0]
        )}px 0px`;

        stopObserver = initObserver($el);
      }
    );
  }

  function destroy() {
    if (unwatch) {
      unwatch();
    }

    rootStore.setAppHeaderOnColor(false);

    if (stopObserver) {
      stopObserver();
    }
  }

  return {
    init,
    destroy,
  };
};
